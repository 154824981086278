import { useTheme } from "@emotion/react";
import { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams as useParamsCompat, useSearchParams } from "react-router-dom-v5-compat";

import { Interaction, InteractionType } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { Button, CommentInput, DateTimeField, DurationField, Mention } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { MultiToggleGroup } from "@megaron/dash-toggle-group";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { InteractionTypeSelect } from "./InteractionTypeSelect";
import { CustomerInteractionDoc } from "@megaron/docs-contracts";

type Props = {
  header: React.ReactNode;
  onClose: () => void;
  interaction?: Interaction | CustomerInteractionDoc;
  queryKey: string | string[];
};

export const InteractionPage: React.FC<Props> = (props) => {
  const { customerId }: { customerId?: string } = useParamsCompat();
  if (!customerId) {
    return <div>Error: Customer ID not provided.</div>;
  }

  return <InteractionPageContent customerId={customerId} {...props} />;
};

const InteractionPageContent: React.FC<Props & { customerId: string }> = (props) => {
  const auth = useContext(IamAuthContext);
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const interactionUuid = searchParams.get("interactionUuid");

  const { isMobile } = useDeviceType();
  const theme = useTheme();

  const productIdsQuery = useClientManager("docs").searchProductIds().useQuery({});
  const saveInteraction = useClientManager("crm").saveInteraction().useMutation();

  const [comment, setComment] = useState<string>(props.interaction?.message ?? "");
  const [mentions, setMentions] = useState<Mention[]>(props.interaction?.mentions ?? []);
  const [interactionDate, setInteractionDate] = useState<Date>(props.interaction?.interactionDate ?? new Date());
  const [selectedType, setSelectedType] = useState<InteractionType | null | undefined>(props.interaction?.type ?? null);
  const [samples, setSamples] = useState<string[]>(props.interaction?.samples ?? []);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(props.interaction?.durationInMinutes ?? 15);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const handleCommentChange = (comment: string, mentions?: Mention[]) => {
    setComment(comment);
    setMentions(mentions || []);
  };

  const handleTypeSelect = (type: InteractionType) => {
    setSelectedType(type);
  };

  const saveInteractionMutation = async () => {
    if (!selectedType) {
      setShowWarning(true);
      return;
    }
    setIsButtonLoading(true);

    saveInteraction.mutate(
      {
        customerId: props.customerId,
        type: selectedType ?? "other",
        message: comment,
        interactionId: (interactionUuid as Uuid) || props.interaction?.uuid,
        samples: samples,
        interactionDate,
        mentions,
        durationInMinutes: duration,
      },

      {
        onSuccess: (r) => {
          setTimeout(() => {
            queryClient.invalidateQueries(props.queryKey);
            setIsButtonLoading(false);
            props.onClose();
          }, 1500);
        },
      },
    );
  };

  const canUserEdit =
    auth.iamUser?.userType === "megarax" &&
    !(props.interaction?.ownerEmail !== auth.iamUser?.email && !!props.interaction?.ownerEmail);

  const samplesOptions = (productIdsQuery.data ?? []).map((sample) => ({
    label: sample,
    value: sample,
  }));

  return (
    <Dialog
      header={props.header}
      onClose={props.onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: "600px", height: isMobile ? "min(100vh - 4rem, 580px)" : "auto" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem", height: "100%", overflow: "auto" }}>
        <div css={{ display: "flex", gap: "1rem", height: "100%", flexWrap: "wrap" }}>
          <DateTimeField
            value={interactionDate}
            onChange={setInteractionDate}
            showTime={true}
            label="Data"
            isDisabled={!canUserEdit}
            disableFuture
          />
          <DurationField value={duration} onChange={(value) => setDuration(value)} label="Czas trwania" />
        </div>
        <CommentInput
          onChange={handleCommentChange}
          enableMentions={true}
          enableTags={false}
          commentValue={comment}
          mentionsValues={mentions}
          userDropdownPlacement="bottom"
        />
        <InteractionTypeSelect
          isDisabled={!canUserEdit}
          onSelect={(type) => handleTypeSelect(type as InteractionType)}
          selectedType={selectedType ?? undefined}
        />
        {showWarning && !selectedType && (
          <div style={{ color: "red", textAlign: "left", fontFamily: theme.primaryFontFamily, fontSize: "14px" }}>
            Wybierz rodzaj interakcji
          </div>
        )}
        <MultiToggleGroup
          label="Próbki i gadzety"
          options={samplesOptions}
          values={samples}
          onChange={setSamples}
          isDisabled={!canUserEdit}
        />
        <Button
          isDisabled={!canUserEdit}
          isLoading={isButtonLoading}
          css={{
            alignSelf: "flex-end",
            marginBottom: "1rem",
          }}
          onClick={saveInteractionMutation}
        >
          Zapisz
        </Button>
      </div>
    </Dialog>
  );
};
