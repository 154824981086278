import { OrderStatus } from "@megarax/crm-contracts";
import { Cancel, CheckCircle, PauseCircle, Pending } from "@mui/icons-material";
import { Avatar, Chip } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  status: OrderStatus;
  className?: string;
}

export const StatusAvatar: React.FunctionComponent<Props> = ({ status, className }) => {
  if (status === "cancelled") return <Cancel className={className} color="error" />;
  if (status === "scheduled") return <CheckCircle className={className} color="success" />;
  if (status === "suspended") return <PauseCircle className={className} color="warning" />;

  return <Pending className={className} />;
};

const useStyles = makeStyles((theme) => ({}));
