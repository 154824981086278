import { useTheme } from "@emotion/react";
import { MdEdit, MdOutlineContactPage } from "react-icons/md";
import { useQueryClient } from "react-query";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { Uuid } from "@megaron/uuid";

import { CustomerAccountDetails } from "./CustomerAccountDetails";
import { CustomerDetails } from "./CustomerDetails";
import { CustomerQuery } from "./CustomerPage";
import { EditTagsDialog } from "./EditTagsDialog";
import { TabHeader } from "./TabHeader";

type Props = {
  customerId: Uuid;
  customerQuery: CustomerQuery;
  onCustomerDataEdit: (isEdited: boolean) => void;
};

export const CustomerProfile: React.FC<Props> = ({ customerQuery, customerId, onCustomerDataEdit }) => {
  const theme = useTheme();

  const queryClient = useQueryClient();

  const { isMobile } = useDeviceType();

  const tagsDialog = useDialogRoute(`/tags`, ({ onClose }) => (
    <EditTagsDialog
      onClose={onClose}
      customerId={customerId}
      customerTags={customerQuery.data?.tags || []}
      onSuccess={() => {
        queryClient.invalidateQueries(customerQuery.key);
      }}
    />
  ));

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      {!isMobile && <TabHeader icon={<MdOutlineContactPage size={18} />} text="Profil" />}
      <QuerySkeleton query={customerQuery} height="34px">
        {(customer) => (
          <div css={{ display: "flex", justifyContent: "space-between" }}>
            <div
              css={{ display: "flex", gap: "7px", alignItems: "center", color: theme.colors.primary, flexWrap: "wrap" }}
            >
              {customer?.tags.map((tag) => (
                <div
                  css={{
                    borderRadius: theme.smallBorderRadius,
                    background: theme.colors.primaryLight,
                    width: "fit-content",
                    alignItems: "cemter",
                    padding: "5px 10px",
                    color: theme.colors.primary,
                    cursor: "pointer",
                  }}
                  key={tag}
                >
                  {`#${tag}`}
                </div>
              ))}

              <Button icon={<MdEdit />} variant="text" onClick={tagsDialog.open}>
                {customerQuery.data?.tags.length ? "Edytuj" : "Dodaj Tag"}
              </Button>
            </div>
          </div>
        )}
      </QuerySkeleton>

      <QuerySkeleton height="41px" query={customerQuery}>
        {(customer) => (
          <CustomerDetails customer={customer} queryKey={customerQuery.key} onCustomerDataEdit={onCustomerDataEdit} />
        )}
      </QuerySkeleton>

      <QuerySkeleton query={customerQuery} height="56px">
        {(customer) =>
          customer.categories.includes("loyaltyUser") ? <CustomerAccountDetails userId={customer.uuid} /> : null
        }
      </QuerySkeleton>

      {tagsDialog.element}
    </div>
  );
};
