import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { currencies } from "./priceList";

export const orderStatuses = ["awaiting", "cancelled", "scheduled", "suspended"] as const;

export const orderSimpleDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  referenceNumber: Serializers.string,
  sellToCustomerUuid: uuidSerializer.nullable(),
  billToCustomerUuid: uuidSerializer.nullable(),
  currency: Serializers.stringOneOf(...currencies),
  netTotal: Serializers.decimal,
  orderDate: dateStringSerializer,
  updatedAt: Serializers.datetime,
  placedAt: Serializers.datetime,
  status: Serializers.stringOneOf(...orderStatuses),
});

export const orderActions = {
  customerRecentOrders: HttpAction({
    path: "/bc/orders/customerRecentOrders",
    method: "get",
    qsSerializer: Serializers.object({
      customerUuid: uuidSerializer,
    }),
    valueSerializer: Serializers.array(orderSimpleDtoSerializer),
  }),
};
