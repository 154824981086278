import { CustomerCategory } from "@megaron/crm-contracts";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { CustomerDoc, getDocId } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { RelationTile } from "./RelationTile";

type Props = {
  customerUuid: Uuid;
  customerCategories: CustomerCategory[];
};

export const RelationList: React.FC<Props> = ({ customerUuid, customerCategories }) => {
  const relationsQuery = useClientManager("crm").customerRelationsQuery().useQuery({
    customerId: customerUuid,
  });

  const customersQuery = useClientManager("docs")
    .retrieveDocs()
    .useQuery({
      docIds: relationsQuery.data
        ? Array.from(new Set(relationsQuery.data.flatMap((r) => [getDocId("customer", r.parentId)])))
        : [],
    });

  return (
    <>
      <QuerySkeleton height="41px" query={relationsQuery}>
        {(relations) => (
          <QuerySkeleton height="41px" query={customersQuery}>
            {(customersData) => {
              const customers = customersData.filter((c) => c?.docType === "customer") as CustomerDoc[];

              const findCustomerByRelationType = (type: string) => {
                const relation = relations.find((r) => r.type === type && r.childId === customerUuid);
                return relation ? customers.find((c) => c.uuid === relation.parentId) : undefined;
              };

              const invitedByParent = findCustomerByRelationType("invitedBy");

              const getRelationValue = (parent: CustomerDoc | undefined, type: string) => {
                const relation = relations.find((r) => r.type === type && r.childId === customerUuid);
                return parent && relation
                  ? {
                      name: parent.name,
                      relatedCustomerUuid: parent.uuid,
                      relationUuid: relation.uuid,
                    }
                  : undefined;
              };

              return (
                <>
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    {getRelationValue(invitedByParent, "invitedBy") && (
                      <RelationTile
                        value={getRelationValue(invitedByParent, "invitedBy")}
                        relationType="invitedBy"
                        customerUuid={customerUuid}
                        queryKey={relationsQuery.key}
                      />
                    )}

                    {customerCategories.includes("pos") && (
                      <>
                        {relations
                          .filter((r) => r.type === "chain" && r.childId === customerUuid)
                          .map((r) => {
                            const customer = customers.find((c) => c.uuid === r.parentId);
                            return customer ? (
                              <RelationTile
                                key={r.uuid}
                                value={{
                                  name: customer.name,
                                  relatedCustomerUuid: customer.uuid,
                                  relationUuid: r.uuid,
                                }}
                                relationType="chain"
                                customerUuid={customerUuid}
                                queryKey={relationsQuery.key}
                              />
                            ) : null;
                          })}

                        {relations
                          .filter((r) => r.type === "distributor" && r.childId === customerUuid)
                          .map((r) => {
                            const customer = customers.find((c) => c.uuid === r.parentId);
                            return customer ? (
                              <RelationTile
                                key={r.uuid}
                                value={{
                                  name: customer.name,
                                  relatedCustomerUuid: customer.uuid,
                                  relationUuid: r.uuid,
                                }}
                                relationType="distributor"
                                customerUuid={customerUuid}
                                queryKey={relationsQuery.key}
                              />
                            ) : null;
                          })}

                        {relations
                          .filter((r) => r.type === "payer" && r.childId === customerUuid)
                          .map((r) => {
                            const customer = customers.find((c) => c.uuid === r.parentId);
                            return customer ? (
                              <RelationTile
                                key={r.uuid}
                                value={{
                                  name: customer.name,
                                  relatedCustomerUuid: customer.uuid,
                                  relationUuid: r.uuid,
                                }}
                                relationType="payer"
                                customerUuid={customerUuid}
                                queryKey={relationsQuery.key}
                              />
                            ) : null;
                          })}
                        <RelationTile
                          relationType="distributor"
                          customerUuid={customerUuid}
                          queryKey={relationsQuery.key}
                          existingRelation={relations.filter(
                            (r) =>
                              r.type === "distributor" && r.childId === customerUuid && r.parentId !== customerUuid,
                          )}
                        />
                        <RelationTile
                          relationType="payer"
                          customerUuid={customerUuid}
                          queryKey={relationsQuery.key}
                          existingRelation={relations.filter(
                            (r) => r.type === "payer" && r.childId === customerUuid && r.parentId !== customerUuid,
                          )}
                        />
                      </>
                    )}

                    {customerCategories.includes("loyaltyUser") && (
                      <>
                        {relations
                          .filter(
                            (r) =>
                              r.type === "supplyPoint" && r.childId === customerUuid && r.parentId !== customerUuid,
                          )
                          .map((r) => {
                            const customer = customers.find((c) => c?.docType === "customer" && c.uuid === r.parentId);
                            return customer ? (
                              <RelationTile
                                key={r.uuid}
                                value={{
                                  name: customer.name,
                                  relationUuid: r.uuid,
                                  relatedCustomerUuid: customer.uuid,
                                }}
                                relationType="supplyPoint"
                                customerUuid={customerUuid}
                                queryKey={relationsQuery.key}
                              />
                            ) : null;
                          })}
                        <RelationTile
                          relationType="supplyPoint"
                          customerUuid={customerUuid}
                          queryKey={relationsQuery.key}
                          existingRelation={relations.filter(
                            (r) =>
                              r.type === "supplyPoint" && r.childId === customerUuid && r.parentId !== customerUuid,
                          )}
                        />
                      </>
                    )}
                  </div>
                </>
              );
            }}
          </QuerySkeleton>
        )}
      </QuerySkeleton>
    </>
  );
};
