import { useTheme } from "@emotion/react";
import React from "react";
import { MdOutlinePhone } from "react-icons/md";

import { TextField } from "@megaron/dash-form";
import { SectionHeader } from "@megaron/dash-page";

type Props = {
  values: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string | undefined;
  };
  handlers: {
    onFirstNameChange: (v: string) => void;
    onLastNameChange: (v: string) => void;
    onPhoneNumberChange: (v: string) => void;
    onEmailChange: (v: string | undefined) => void;
  };
  isCustomerDetailsEditDisabled?: boolean;
};

export const CustomerPersonalDetails: React.FC<Props> = ({ values, handlers, isCustomerDetailsEditDisabled }) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SectionHeader isHr>Dane osobowe</SectionHeader>

      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        <div css={{ display: "flex", alignItems: "center", gap: "0.625rem" }}>
          <TextField
            label="Imię"
            value={values.firstName}
            onChange={handlers.onFirstNameChange}
            isDisabled={isCustomerDetailsEditDisabled}
          />
          <TextField
            label="Nazwisko"
            value={values.lastName}
            onChange={handlers.onLastNameChange}
            isDisabled={isCustomerDetailsEditDisabled}
          />
        </div>
        <div css={{ position: "relative" }}>
          <TextField
            label="Nr telefonu"
            value={values.phoneNumber}
            onChange={handlers.onPhoneNumberChange}
            isDisabled
          />
          <a
            href={`tel:${values.phoneNumber}`}
            css={{
              position: "absolute",
              bottom: "0.5rem",
              right: "0.375rem",
              padding: "0.125rem",
              background: "none",
              textDecoration: "none",
              color: theme.colors.primary,
              height: "24px",
            }}
          >
            <MdOutlinePhone size={20} />
          </a>
        </div>
        <TextField
          label="Email"
          value={values.email ?? ""}
          onChange={handlers.onEmailChange}
          isDisabled={isCustomerDetailsEditDisabled}
        />
      </div>
    </div>
  );
};

const formatPhoneNumber = (phoneNumber: string) => {
  const formattedNumber = phoneNumber.replace(/(\+\d{2})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4");
  return formattedNumber;
};
