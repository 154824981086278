import Decimal from "decimal.js";

import { Failure, Ok } from "@megaron/result";

export type InputRow = {
  customerName: string;
  discount: Decimal | null;
};

export const rawToInput = (raw: unknown) => {
  if (!Array.isArray(raw)) return Failure("InvalidInput");
  if (raw.length === 0) return Failure("InvalidInput");

  const mapped: InputRow[] = [];

  for (const rawRow of raw) {
    if (typeof rawRow !== "object") return Failure("InvalidInput");

    const row = rawToInputRow(rawRow);

    if (row.isFailure) return row;

    mapped.push(row.value);
  }

  return Ok(mapped);
};

export const rawToInputRow = (raw: { [key: string]: unknown }) => {
  if (!("Nazwa klienta" in raw)) return Failure("MissingCustomerName");

  if (!("Rabat posprzedażowy" in raw)) return Failure("MissingPostSaleDiscount");

  if (typeof raw["Nazwa klienta"] !== "string") return Failure("InvalidCustomerName");

  const discountRate = raw["Rabat posprzedażowy"];

  const isDiscountRateValid = !Number.isNaN(Number(discountRate));

  if (!isDiscountRateValid) return Failure("InvalidDiscountRate");

  return Ok<InputRow>({
    discount: discountRate ? new Decimal(discountRate as string) : null,
    customerName: raw["Nazwa klienta"] as string,
  });
};
