import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const itemSerializer = Serializers.object({
  itemId: Serializers.string,
  productId: Serializers.string,
  tradeItemName: Serializers.string,
  gtin: Serializers.string.nullable(),
  box: Serializers.integer.nullable(),
  layer: Serializers.integer.nullable(),
  pallet: Serializers.integer.nullable(),
  size: Serializers.decimal,
  bcNumber: Serializers.string.nullable(),
  productGroup: Serializers.string,
  description: Serializers.string,
  cost: Serializers.decimal.nullable(),
  isArchived: Serializers.boolean,
});

export type Item = SerializerValue<typeof itemSerializer>;

export const items = {
  itemsQuery: HttpAction({
    path: "/items",
    method: "get",
    qsSerializer: Serializers.object({
      itemIds: Serializers.string.array().asJsonString(),
    }).partial(),
    requiresAuth: true as const,
    valueSerializer: Serializers.object({ items: itemSerializer.array(), count: Serializers.integer }),
  }),

  itemQuery: HttpAction({
    path: "/items/:itemId",
    method: "get",
    paramsSerializer: Serializers.object({
      itemId: Serializers.string,
    }),
    requiresAuth: true as const,
    valueSerializer: itemSerializer,
  }),

  saveItem: HttpAction({
    path: "/items",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: itemSerializer,
    valueSerializer: itemSerializer,
  }),

  saveItemCosts: HttpAction({
    path: "/items/costs",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      itemId: Serializers.string,
      cost: Serializers.decimal.nullable(),
    }).array(),
    valueSerializer: itemSerializer.array(),
  }),
};
