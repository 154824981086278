import { ResourceNodes, ResourceParamNode } from "@megarax/rest-resource";
import {
  MSerializer,
  MSerializers,
  serializerExtensions,
  SerializerExtensions,
  ValidationError,
} from "@megarax/serializers";
import { formatISO } from "date-fns";

import { Failure, Ok } from "@megaron/result";

export type Month = string & { __brand: "Month" };

export const monthFromTime = (time: Date): Month =>
  formatISO(time, { representation: "date" }).substring(0, 7) as Month;

export const startOfMonth = (month: Month) => new Date(month + "-01");

export const monthRegex = /^\d{4}-(0[1-9]|1[012])$/;

export const isMonth = (str: string): str is Month => str.match(monthRegex) !== null;

export const validateMonth = (str: string) => {
  if (!isMonth(str)) return Failure("InvalidMonth");
  return Ok(str as Month);
};

export const monthSerializer: MSerializer<Month> & SerializerExtensions<Month> = {
  serialize: (month) => month,
  forceDeserialize: (raw: unknown) => {
    if (typeof raw !== "string") throw new ValidationError("Month needs to be a string");
    const result = validateMonth(raw);
    if (result.isFailure) throw new ValidationError("Invalid month");
    return result.value;
  },
  ...serializerExtensions(),
};

export const MonthResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  path: string,
  nested: TNestedActions,
): ResourceParamNode<Month, TNestedActions> => ({
  children: nested,
  paramName,
  paramPattern: "\\d{4}-(0[1-9]|1[012])",
  paramSerializer: monthSerializer,
  path,
});
