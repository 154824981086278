import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { interactionMentionSerializer } from "./history";

export const loyaltyCustomerAddedTopic = PubsubTopic({
  name: "crm-loyalty-customer-added",
  serializer: Serializers.object({
    accountUuid: uuidSerializer,
  }),
});

export type LoyaltyCustomerAddedMessage = SerializerValue<typeof loyaltyCustomerAddedTopic.serializer>;

export const customerChangeTypes = [
  "added",
  "updated",
  "regionChanged",
  "tagsChanged",
  "purchaseFunnelChanged",
  "statusChanged",
  "interactionSaved",
  "purchaseAdded",
  "relationAdded",
  "relationRemoved",
] as const;

export const customerChangedTopic = PubsubTopic({
  name: "crm-customer-changed",
  serializer: Serializers.object({
    customerUuid: uuidSerializer,
    changeType: Serializers.stringOneOf<(typeof customerChangeTypes)[number]>(...customerChangeTypes),
  }),
});

export type CustomerChangedMessage = SerializerValue<typeof customerChangedTopic.serializer>;

export const regionChangedTopic = PubsubTopic({
  name: "crm-region-changed",
  serializer: Serializers.object({
    regionName: Serializers.string.nullable(),
    newOwnerEmail: Serializers.string.nullable(),
  }),
});

export type RegionChangedMessage = SerializerValue<typeof regionChangedTopic.serializer>;

export const macroregionChangedTopic = PubsubTopic({
  name: "crm-macroregion-changed",
  serializer: Serializers.object({
    macroregionName: Serializers.string.nullable(),
    oldRegions: Serializers.string.array(),
    newRegions: Serializers.string.array(),
    authorizedUserEmails: Serializers.string.array(),
  }),
});
export type MacroregionChangedMessage = SerializerValue<typeof macroregionChangedTopic.serializer>;

export const customerAssignedToRegionTopic = PubsubTopic({
  name: "crm-customer-assigned-to-region",
  serializer: Serializers.object({
    customerUuid: uuidSerializer,
    regionOwnerEmail: Serializers.string,
  }),
});

export type CustomerAssignedToRegionMessage = SerializerValue<typeof customerAssignedToRegionTopic.serializer>;

export const interactionSavedTopic = PubsubTopic({
  name: "crm-interaction-posted",
  serializer: Serializers.object({
    customerId: uuidSerializer,
    interactionId: uuidSerializer,
    postedBy: Serializers.string,
    message: Serializers.string,
    mentions: interactionMentionSerializer.array(),
  }),
});

export type InteractionSavedMessage = SerializerValue<typeof interactionSavedTopic.serializer>;

export const itemChangedTopic = PubsubTopic({
  name: "crm-item-changed",
  serializer: Serializers.object({
    itemId: Serializers.string,
  }),
});

export type ItemChangedMessage = SerializerValue<typeof itemChangedTopic.serializer>;

export const crmTopics = [
  loyaltyCustomerAddedTopic,
  customerChangedTopic,
  regionChangedTopic,
  macroregionChangedTopic,

  customerAssignedToRegionTopic,
  interactionSavedTopic,
  itemChangedTopic,
];
