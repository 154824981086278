import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

const visitDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  userId: Serializers.integer,
  date: Serializers.datetime,
  customerUuid: uuidSerializer,
  isCancelled: Serializers.boolean,
  startTime: Serializers.datetime.nullable(),
  endTime: Serializers.datetime.nullable(),
  scheduledDurationMinutes: Serializers.integer.nullable(),
  scheduledStartTime: Serializers.datetime.nullable(),
});

export type NewVisitDto = SerializerValue<typeof visitDtoSerializer>;

export const visitActions = {
  userDay: HttpAction({
    path: "/crm/visits/userDay",
    method: "get",
    qsSerializer: Serializers.object({
      userId: Serializers.integer,
      date: Serializers.dateOnly,
    }),
    valueSerializer: Serializers.array(visitDtoSerializer),
  }),
  visit: HttpAction({
    path: "/crm/visits/uuid/:uuid",
    method: "get",
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }),
    valueSerializer: visitDtoSerializer,
  }),
};
