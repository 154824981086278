import { useTheme } from "@emotion/react";

import { PostSaleDiscount } from "@megaron/crm-contracts";
import { useDeviceType } from "@megaron/dash-mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";

type Props = {
  postSaleDiscounts: PostSaleDiscount[];
  isLoading: boolean;
};

export const PostSaleDiscountsTable: React.FC<Props> = ({ postSaleDiscounts, isLoading }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const tableColumns: TableHeadColumn<string>[] = [
    ...tableHeadingsList.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (discount: PostSaleDiscount): RowCellContent[] => {
    return [
      {
        cellCss: {
          color: theme.colors.primary,
        },
        element: discount.customerId,
      },
      {
        cellCss: {
          color: theme.colors.primary,
        },
        element: discount.customerName,
      },
      {
        cellCss: {
          color: "black",
        },
        element: `${discount.discount?.toFixed(2)}%`,
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
      <Table columns={tableColumns} css={{ maxWidth: "60rem", minWidth: isMobile ? "100%" : "unset" }}>
        <TableBody
          isLoading={isLoading}
          rows={postSaleDiscounts.map((discount) => ({
            uuid: discount.customerId,
            cellsContent: getTableRowCellsContent(discount),
            css: {
              "td:first-child": {
                padding: "0.75rem",
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

const tableHeadingsList = ["Id klienta", "Nazwa klienta", "Rabat posprzedażowy"];
