import { formatISO, parseISO } from "date-fns";

import { Failure, Ok } from "@megaron/result";
import { Serializer, SerializerExtensions, serializerExtensions } from "@megaron/serializers";

export type DateString = string & { __brand: "DateString" };

export const validateDateString = (str: string) => {
  try {
    const date = new Date(str);
    const formattedDate = formatISO(date, { representation: "date" });
    if (formattedDate !== str) return Failure("InvalidDateString");
    return Ok<DateString>(str as DateString);
  } catch (e) {
    return Failure("InvalidDateString");
  }
};

export const dateStringSerializer: Serializer<DateString> & SerializerExtensions<DateString> = {
  serialize: (dateString) => dateString,
  deserialize: (raw: unknown) => {
    if (typeof raw !== "string") return Failure("NotAString");
    return validateDateString(raw);
  },
  ...serializerExtensions(),
};

export const toDateString = (date: Date) => formatISO(date, { representation: "date" }) as DateString;

export function fromDateString(str: DateString): Date;
export function fromDateString(str: DateString | null): Date | null;
export function fromDateString(str: DateString | undefined): Date | undefined;
export function fromDateString(str: DateString | null | undefined): Date | null | undefined;
export function fromDateString(str: DateString | null | undefined) {
  if (str === null) return null;
  if (str === undefined) return undefined;
  return parseISO(str);
}

export const dateStringsInRange = (since: Date | DateString, until: Date | DateString): DateString[] => {
  since = typeof since === "string" ? fromDateString(since) : since;
  until = typeof until === "string" ? fromDateString(until) : until;

  const date = new Date(since);

  const dates: Date[] = [];

  while (date <= until) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates.map(toDateString);
};
