import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export type Month = string & { __brand: "Month" };

export const monthRegex = /^\d{4}-(0[1-9]|1[012])$/;

export const month: Serializer<Month> & SerializerExtensions<Month> = {
  serialize: (month) => {
    if (!monthRegex.test(month)) throw new Error("InvalidMonthFormat");
    return month;
  },
  deserialize: (raw: unknown) => {
    if (typeof raw !== "string") return Failure("NotAString");
    if (!monthRegex.test(raw)) return Failure("InvalidMonthFormat");
    return Ok(raw as Month);
  },
  ...serializerExtensions(),
};
