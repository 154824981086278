import { useEffect } from "react";

import { customerCategories, customerStatuses, relationTypes } from "@megaron/crm-contracts";
import { useQsFilters } from "@megaron/dash-filters";
import { customerSortFields } from "@megaron/docs-contracts";
import { visibleCustomersTableColumnsKey } from "@megaron/megarax-links";
import { useRegion } from "@megaron/region-context";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const filtersSerializer = Serializers.object({
  page: Serializers.integer,
  searchText: Serializers.string,
  sort: Serializers.sortFilter(customerSortFields).optional(),
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  pos: Serializers.array(Serializers.string).optional(),
  scannedProducts: Serializers.array(Serializers.string).optional(),
  tags: Serializers.array(Serializers.string).optional(),
  regions: Serializers.array(Serializers.string).optional(),
  isUnassigned: Serializers.boolean.optional(),
  status: Serializers.stringOneOf(...customerStatuses)
    .array()
    .optional(),
  categories: Serializers.stringOneOf(...customerCategories)
    .array()
    .optional(),
  groupFilter: Serializers.stringOneOf(...visibleCustomersTableColumnsKey),
  parents: Serializers.array(Serializers.string).optional(),
  relation: Serializers.object({
    relationType: Serializers.stringOneOf(...relationTypes),
    parents: Serializers.string.array(),
  })
    .asJsonString()
    .optional(),
});

export type Filters = SerializerValue<typeof filtersSerializer>;

export const useCustomersFilters = () => {
  const { region, isLoaded } = useRegion();

  const defaultFilters: Filters = {
    page: 0,
    searchText: "",
    sort: undefined,
    isPro: false,
    isAffiliate: false,
    pos: undefined,
    tags: undefined,
    regions: region ? [region] : undefined,
    isUnassigned: undefined,
    scannedProducts: undefined,
    status: ["unverified", "verified", "vip"],
    categories: undefined,
    groupFilter: "all",
    parents: undefined,
    relation: undefined,
  };

  const { filters, setFilters } = useQsFilters(filtersSerializer, defaultFilters);

  const setFilter =
    <Tkey extends keyof Filters>(key: Tkey) =>
    (value: Filters[Tkey]) => {
      if (key === "groupFilter") {
        if (value === "all") {
          setFilters({
            ...defaultFilters,
            groupFilter: "all",
            categories: undefined,
          });
          return;
        }

        if (value === "pos") {
          setFilters({
            ...defaultFilters,
            groupFilter: "pos",
            categories: ["pos"],
          });
          return;
        }

        if (value === "loyalty") {
          setFilters({
            ...defaultFilters,
            regions: undefined,
            groupFilter: "loyalty",
            categories: ["loyaltyUser"],
          });
          return;
        }

        if (value === "distributors") {
          setFilters({
            ...defaultFilters,
            regions: undefined,
            groupFilter: "distributors",
            categories: ["distributor"],
          });
          return;
        }

        if (value === "payers") {
          setFilters({
            ...defaultFilters,
            regions: undefined,
            groupFilter: "payers",
            categories: ["payer"],
          });
          return;
        }

        if (value === "chains") {
          setFilters({
            ...defaultFilters,
            regions: undefined,
            groupFilter: "chains",
            categories: ["chain"],
          });
        }

        return;
      }

      setFilters({ ...filters, [key]: value });
    };

  useEffect(() => {
    if (
      (filters.regions && filters.regions.length > 0) ||
      (filters.groupFilter !== "all" && filters.groupFilter !== "pos")
    ) {
      return;
    }

    if (isLoaded && region) {
      setFilter("regions")([region]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return { filters, setFilter, defaultFilters, setFilters };
};
