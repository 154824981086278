import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { baseDocSerializer } from "../baseDoc";

export const itemDocSerializer = Serializers.object({
  ...baseDocSerializer("item").fields,

  itemId: Serializers.string,
  productId: Serializers.string,
  tradeItemName: Serializers.string,
  gtin: Serializers.string.nullable(),
  box: Serializers.integer.nullable(),
  layer: Serializers.integer.nullable(),
  pallet: Serializers.integer.nullable(),
  size: Serializers.decimal,
  bcNumber: Serializers.string.nullable(),
  productGroup: Serializers.string,
  description: Serializers.string,
  cost: Serializers.decimal.nullable(),
});

export type ItemDoc = SerializerValue<typeof itemDocSerializer>;

export const itemsSortFields = ["box", "pallet", "layer", "size", "gtin"] as const;
export type ItemSortField = (typeof itemsSortFields)[number];

export const itemActions = {
  searchItems: HttpAction({
    path: "/items",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      sort: Serializers.sortFilter(itemsSortFields).asJsonString(),
      isLoyalty: Serializers.jsonString(Serializers.boolean),
      groups: Serializers.jsonString(Serializers.array(Serializers.string)),
      isArchived: Serializers.jsonString(Serializers.boolean),
    }).partial(),
    valueSerializer: Serializers.paginatedList(itemDocSerializer),
    requiresAuth: true,
  }),

  searchProductGroups: HttpAction({
    path: "/items/productsGroups",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
    }).partial(),
    valueSerializer: Serializers.string.array(),
    requiresAuth: true,
  }),

  searchProductIds: HttpAction({
    path: "/items/productsIds",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
    }).partial(),
    valueSerializer: Serializers.string.array(),
    requiresAuth: true,
  }),
};
