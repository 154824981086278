import React from "react";
import { MdMap, MdPersonAdd } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader } from "@megaron/dash-page";
import { SearchWithPaginationSticky } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { Serializers } from "@megaron/serializers";

import { CustomerList } from "./CustomerList";
import { CustomersFilters } from "./CustomersFilters";
import { CustomersGroupFilters } from "./CustomersGroupFilters";
import { CustomerTable } from "./CustomerTable";
import { DownloadContactsCsvButton } from "./DownloadContactsCsvButton";
import { DownloadCustomersCsvButton } from "./DownloadCustomersCsvButton";
import { useCustomersFilters } from "./useCustomersFilters";
import { useCustomerVisibleTableColumns } from "./useCustomerVisibleTableColumns";

export const CustomersHome: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDesktop, isMobile } = useDeviceType();

  const { visibleTableColumns, handleVisibleColumnsChange } = useCustomerVisibleTableColumns();

  const pageSize = 30;
  const { filters, setFilter, setFilters } = useCustomersFilters();

  const searchQuery = useClientManager("docs")
    .searchCustomers()
    .useQuery(
      {
        offset: filters.page * pageSize,
        limit: pageSize,
        searchText: filters.searchText,
        sort: filters.sort,
        isPro: filters.isPro,
        isAffiliate: filters.isAffiliate,
        tags: filters.tags,
        scannedProducts: filters.scannedProducts,
        regions: filters.regions,
        isUnassigned: filters.isUnassigned,
        categories: filters.categories,
        status: filters.status,
        relation: filters.relation,
      },
      { refetchInterval: 300000, refetchOnWindowFocus: true, refetchOnReconnect: true },
    );

  const getVisibleTableColumns = () => {
    if (filters.groupFilter === "all") {
      return visibleTableColumns?.all;
    }

    if (filters.groupFilter === "distributors") {
      return visibleTableColumns?.distributors;
    }

    if (filters.groupFilter === "loyalty") {
      return visibleTableColumns?.loyalty;
    }

    if (filters.groupFilter === "pos") {
      return visibleTableColumns?.pos;
    }

    if (filters.groupFilter === "payers") {
      return visibleTableColumns?.payers;
    }

    if (filters.groupFilter === "chains") {
      return visibleTableColumns?.chains;
    }

    return [];
  };

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "100%",
      }}
    >
      <PageHeader
        actions={
          <Button icon={<MdPersonAdd />} onClick={() => navigate("/crm/customers/new")}>
            Dodaj
          </Button>
        }
      >
        Klienci
      </PageHeader>

      <CustomersGroupFilters
        groupFilter={filters.groupFilter}
        onGroupFilterChange={setFilter("groupFilter")}
        visibleTableColumnsConfig={visibleTableColumns}
        onVisibleTableColumnsConfigChange={handleVisibleColumnsChange}
      />

      <SearchWithPaginationSticky
        onSearchChange={setFilter("searchText")}
        searchValue={filters.searchText}
        page={filters.page}
        onPageChange={setFilter("page")}
        pageSize={pageSize}
        itemCount={searchQuery.data?.count}
      >
        <div css={{ display: "flex", alignItems: "stretch" }}>
          <Button
            color="normal"
            icon={<MdMap />}
            css={{ borderRadius: "0" }}
            onClick={() => navigate(`${location.pathname}/map${location.search}`)}
          />
        </div>
      </SearchWithPaginationSticky>

      <CustomersFilters
        filters={filters}
        handlers={{
          onSortChange: setFilter("sort"),
          onProChange: setFilter("isPro"),
          onAffiliateChange: setFilter("isAffiliate"),
          onUnassignedChange: setFilter("isUnassigned"),
          onScannedProductsChange: setFilter("scannedProducts"),
          onRegionsChange: setFilter("regions"),
          onTagsChange: setFilter("tags"),
          onStatusChange: setFilter("status"),
          onCategoriesChange: setFilter("categories"),
          onRelationChange: setFilter("relation"),
        }}
      />

      <div css={{ display: isMobile ? "none" : "flex", alignItems: "center", gap: "0.5rem", marginBottom: "1rem" }}>
        <DownloadCustomersCsvButton filters={filters} />
        <DownloadContactsCsvButton />
      </div>

      <QuerySkeleton query={searchQuery}>
        {(result) =>
          isDesktop ? (
            <CustomerTable
              customers={result.items}
              isLoading={searchQuery.isFetching}
              onSortChange={setFilter("sort")}
              activeSortValue={filters.sort}
              visibleTableColumns={getVisibleTableColumns() || []}
              queryKey={searchQuery.key}
              onFiltersChange={setFilters}
              filters={filters}
            />
          ) : (
            <CustomerList customers={result.items} queryKey={searchQuery.key} />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};

export type SortFilterValue =
  | Serializers.SortFilter<"totalMassKg" | "lastInteractionTime" | "lastPurchaseTime">
  | undefined;
