import "./infoWindowStyle.css";

import { useTheme } from "@emotion/react";
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from "@react-google-maps/api";
import { differenceInCalendarDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

import { CustomerDoc } from "@megaron/docs-contracts";

import { CustomerTile } from "./CustomerTile";

type Props = {
  customers: CustomerDoc[];
  queryKey: string | string[];
};

export const CustomersMap: React.FC<Props> = ({ customers, queryKey }) => {
  const theme = useTheme();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  });

  const [selectedCustomer, setSelectedCustomer] = useState<CustomerDoc | null>(null);
  const [zoom, setZoom] = useState<number>(7);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: 52.0692, lng: 19.4802 });

  const handleMarkerClick = (customer: CustomerDoc) => {
    setSelectedCustomer(customer);
    setMapCenter({ lat: Number(customer.address.latitude), lng: Number(customer.address.longitude) });
    setZoom(15);
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const customerId = searchParams.get("customerId");
    if (!customerId) return;

    const customer = customers.find((customer) => customer.uuid === customerId);
    if (!customer) return;

    handleMarkerClick(customer);
  }, [customers, searchParams]);

  const handleMapClick = () => {
    setSelectedCustomer(null);
  };

  const isInactive = (time: Date) => differenceInCalendarDays(new Date(), time) > 90;

  const getActivityColor = (time: Date | null, isArchived: boolean) => {
    if (isArchived) return theme.colors.border;
    if (!time) return theme.colors.primary;
    return isInactive(time) ? theme.colors.danger : theme.colors.success;
  };

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%", overflow: "hidden" }}
        center={mapCenter}
        zoom={zoom}
        onClick={handleMapClick}
        options={{
          disableDefaultUI: true,
          streetViewControl: false,
          styles: [
            { elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off" }] },
            { featureType: "poi", stylers: [{ visibility: "off" }] },
          ],
        }}
      >
        <MarkerClusterer>
          {(clusterer) => (
            <>
              {customers
                .filter((customer) => customer.address.latitude !== null && customer.address.longitude !== null)
                .map((customer) => (
                  <Marker
                    key={customer.uuid}
                    position={{ lat: Number(customer.address.latitude), lng: Number(customer.address.longitude) }}
                    onClick={() => handleMarkerClick(customer)}
                    clusterer={clusterer}
                    icon={{
                      path: window.google.maps.SymbolPath.CIRCLE,
                      fillColor: getActivityColor(customer.lastPurchaseTime, customer.isArchived),
                      fillOpacity: 1,
                      strokeWeight: 0,
                      scale: 10,
                    }}
                  />
                ))}
            </>
          )}
        </MarkerClusterer>

        {selectedCustomer && (
          <InfoWindow
            position={{
              lat: Number(selectedCustomer.address.latitude),
              lng: Number(selectedCustomer.address.longitude),
            }}
            onCloseClick={() => setSelectedCustomer(null)}
            options={{ pixelOffset: new window.google.maps.Size(0, -20) }}
          >
            <CustomerTile customer={selectedCustomer} mapView queryKey={queryKey} />
          </InfoWindow>
        )}
      </GoogleMap>
    )
  );
};
