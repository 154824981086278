import { ThreadSortField } from "@megaron/docs-contracts";

import { InvoiceThreadFlat } from "./AllThreadsHome";

export const tableHeadingsList = [
  { label: "Numer wątku", sortFieldName: "referenceNumber", key: "referenceNumber", isDefault: true },
  { label: "Tytuł", sortFieldName: "title", key: "title", isDefault: true },
  { label: "Wystawca", sortFieldName: "invoiceIssuer", key: "issuer", isDefault: true },
  { label: "Numer Faktury", sortFieldName: "invoiceNumber", key: "invoiceNumber", isDefault: true },
  { label: "Kwota netto", sortFieldName: "invoiceTotal", key: undefined, isDefault: true },
  { label: "Data utw.", sortFieldName: "createdAt", key: "createdAt", isDefault: true },
  { label: "Data wyst.", sortFieldName: "invoiceIssueDate", key: "issueDate", isDefault: true },
  { label: "Deadline", sortFieldName: "deadline", key: "deadline", isDefault: true },
  { label: "Termin płatności", sortFieldName: "dueDate", key: "dueDate", isDefault: true },
  { label: "Data zatw.", sortFieldName: "approvedAt", key: "approvedAt", isDefault: true },
  { label: "Podpisy", sortFieldName: undefined, key: undefined, isDefault: true },
  { label: "Oczekuje na", sortFieldName: undefined, key: undefined, isDefault: true },
  { label: "Komentarz", sortFieldName: undefined, key: undefined, isDefault: false },
  { label: "Nr dekretu", sortFieldName: "decreeNumber", key: "decreeNumber", isDefault: false },
] satisfies {
  label: string;
  sortFieldName: ThreadSortField | undefined;
  key?: keyof InvoiceThreadFlat;
  isDefault: boolean;
}[];
