import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

const customerEventSerializer = Serializers.first(
  Serializers.object({
    type: Serializers.stringOneOf("productCodeRedeemed"),
    customerUuid: uuidSerializer,
    time: Serializers.datetime,
    productId: Serializers.string,
    transactionUuid: uuidSerializer,
    massKg: Serializers.decimal,
    redeemedAt: Serializers.datetime,
  }),
  Serializers.object({
    type: Serializers.stringOneOf("rewardPurchased"),
    customerUuid: uuidSerializer,
    time: Serializers.datetime,
    rewardId: Serializers.string,
    purchaseUuid: uuidSerializer,
    price: Serializers.decimal,
  }),
  Serializers.object({
    type: Serializers.stringOneOf("proStatusEarned"),
    customerUuid: uuidSerializer,
    time: Serializers.datetime,
  }),
  Serializers.object({
    type: Serializers.stringOneOf("affiliateCodeClaimed"),
    customerUuid: uuidSerializer,
    time: Serializers.datetime,
  }),
  Serializers.object({
    type: Serializers.stringOneOf("affiliateCodeRedeemed"),
    customerUuid: uuidSerializer,
    time: Serializers.datetime,
    transactionUuid: uuidSerializer,
    invitedByUuid: uuidSerializer,
    codeId: Serializers.string,
  }),
  Serializers.object({
    type: Serializers.stringOneOf("userInvited"),
    customerUuid: uuidSerializer,
    time: Serializers.datetime,
    invitedUserUuid: uuidSerializer,
    codeId: Serializers.string,
  }),
);

export type CustomerEvent = SerializerValue<typeof customerEventSerializer>;

export const customerEventsQuery = HttpAction({
  path: "/accounts/cutomerEvents",
  method: "get",
  requiresAuth: true as const,
  qsSerializer: Serializers.object({
    customerUuids: uuidSerializer.array().asJsonString(),
  }).partial(),
  valueSerializer: Serializers.array(customerEventSerializer),
  errorSerializer: Serializers.stringOneOf(),
});
