import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { baseDocSerializer } from "../baseDoc";

export const customerEventDocSerializer = Serializers.object({
  ...baseDocSerializer("customer-event").fields,
  customerUuid: uuidSerializer,

  payload: Serializers.first(
    Serializers.object({
      type: Serializers.stringOneOf("productCodeRedeemed"),
      time: Serializers.datetime,
      productId: Serializers.string,
      transactionUuid: uuidSerializer,
      massKg: Serializers.decimal,
      redeemedAt: Serializers.datetime,
    }),
    Serializers.object({
      type: Serializers.stringOneOf("rewardPurchased"),
      time: Serializers.datetime,
      rewardId: Serializers.string,
      purchaseUuid: uuidSerializer,
      price: Serializers.decimal,
    }),
    Serializers.object({
      type: Serializers.stringOneOf("proStatusEarned"),
      time: Serializers.datetime,
    }),
    Serializers.object({
      type: Serializers.stringOneOf("affiliateCodeClaimed"),
      time: Serializers.datetime,
    }),
    Serializers.object({
      type: Serializers.stringOneOf("affiliateCodeRedeemed"),
      time: Serializers.datetime,
      transactionUuid: uuidSerializer,
      invitedByUuid: uuidSerializer,
      codeId: Serializers.string,
    }),
    Serializers.object({
      type: Serializers.stringOneOf("userInvited"),
      time: Serializers.datetime,
      invitedUserUuid: uuidSerializer,
      codeId: Serializers.string,
    }),
    Serializers.object({
      type: Serializers.stringOneOf("userJoined"),
      time: Serializers.datetime,
    }),
    Serializers.object({
      type: Serializers.stringOneOf("userRegistered"),
      time: Serializers.datetime,
    }),
    Serializers.object({
      type: Serializers.stringOneOf("accountDeleted"),
      time: Serializers.datetime,
    }),
  ),
});

export type CustomerEventDoc = SerializerValue<typeof customerEventDocSerializer>;

export const customerEventActions = {
  customerEventQuery: HttpAction({
    path: "/customer/:customerUuid/events",
    paramsSerializer: Serializers.object({
      customerUuid: uuidSerializer,
    }),
    valueSerializer: Serializers.paginatedList(customerEventDocSerializer),
    requiresAuth: true,
  }),
};
