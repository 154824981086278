import { authAttributeSerializer } from "@megaron/access-control";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { customerCategories } from "./categories";
import { customerProductSerializer } from "./purchases";
import { relationTypes } from "./relation";

export const loyaltyRegistrationStatuses = ["preregistered", "joined", "registered", "deleted"] as const;
export type LoyaltyRegistrationStatus = (typeof loyaltyRegistrationStatuses)[number];

export const customerStatuses = ["vip", "verified", "unverified", "inactive"] as const;
export type CustomerStatus = (typeof customerStatuses)[number];

export const tags = [
  "glazurnik",
  "targi",
  "szkolenie",
  "social_media",
  "hurtownia",
  "polecenia",
  "baza_specjalista",
  "testy",
  "sucha_zabudowa",
];

export const customerAddressSerializer = Serializers.object({
  country: Serializers.string,
  city: Serializers.string,
  street: Serializers.string,
  postalCode: Serializers.string,
  latitude: Serializers.decimal.nullable(),
  longitude: Serializers.decimal.nullable(),
  googlePlaceId: Serializers.string.nullable(),
});

export type CustomerAddress = SerializerValue<typeof customerAddressSerializer>;

const customersDetailsSerializer = Serializers.object({
  uuid: uuidSerializer,
  categories: Serializers.stringOneOf(...customerCategories).array(),
  phoneNumber: Serializers.string,
  email: Serializers.email.nullable(),
  address: customerAddressSerializer,
  region: Serializers.string.nullable(),
  name: Serializers.string,
  firstName: Serializers.string,
  lastName: Serializers.string,
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  loyaltyRegistrationStatus: Serializers.stringOneOf(...loyaltyRegistrationStatuses).nullable(),
  tags: Serializers.array(Serializers.string),
  status: Serializers.stringOneOf(...customerStatuses),
  lastPurchaseTime: Serializers.datetime.nullable(),
  totalMassKg: Serializers.decimal,
  postSaleDiscount: Serializers.decimal.nullable().optional(),
  isArchived: Serializers.boolean,
});

export type CustomerDetails = SerializerValue<typeof customersDetailsSerializer>;

export const customerListObjectSerializer = Serializers.object({
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
});

export const customerListSerializer = Serializers.object({
  items: Serializers.array(customerListObjectSerializer),
  count: Serializers.integer,
});

export type CustomerListObject = SerializerValue<typeof customerListObjectSerializer>;

export const newCustomerSerializer = Serializers.object({
  uuid: uuidSerializer,
  region: Serializers.string.nullable(),
});

export type NewCustomer = SerializerValue<typeof newCustomerSerializer>;

export const saveCustomerDtoSerializer = Serializers.object({
  name: Serializers.string,
  region: Serializers.string.nullable(),
  phoneNumber: Serializers.string,
  firstName: Serializers.string,
  lastName: Serializers.string,
  email: Serializers.email.nullable(),
  address: customerAddressSerializer,
  status: Serializers.stringOneOf(...customerStatuses),
  tags: Serializers.array(Serializers.string),
  categories: Serializers.stringOneOf(...customerCategories).array(),
}).partial();

export type SaveCustomerDto = SerializerValue<typeof saveCustomerDtoSerializer>;

export const sortSerializer = Serializers.object({
  field: Serializers.string,
  order: Serializers.stringOneOf("ASC", "DESC"),
});

export type SortFieldKeys = keyof {
  scan: string;
  mass: string;
  interaction: string;
};

export type Sort = SerializerValue<typeof sortSerializer>;

const customerBulkQueryEntrySerializer = Serializers.object({
  uuid: uuidSerializer,
  name: Serializers.string,
  phoneNumber: Serializers.string,
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  interactionCount: Serializers.integer,
  lastInteraction: Serializers.object({
    uuid: uuidSerializer,
    createdAt: Serializers.datetime,
    ownerEmail: Serializers.string,
    message: Serializers.string,
  }).nullable(),
  lastPurchaseTime: Serializers.datetime.nullable(),
  postalCode: Serializers.string,
  totalMassKg: Serializers.decimal,
  email: Serializers.email.nullable(),
  products: Serializers.array(customerProductSerializer),
  region: Serializers.string.nullable(),
  isArchived: Serializers.boolean,
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  tags: Serializers.array(Serializers.string),
  regionOwnerEmail: Serializers.string.nullable(),
  authorizedAttributes: authAttributeSerializer.array(),
  address: customerAddressSerializer,
  status: Serializers.stringOneOf(...customerStatuses),
  categories: Serializers.stringOneOf(...customerCategories).array(),
  parents: Serializers.object({
    uuid: Serializers.string,
    relationType: Serializers.stringOneOf(...relationTypes),
  }).array(),
  invitedChildrenCount: Serializers.integer,
  supplyPointChildrenCount: Serializers.integer,
  payerChildrenCount: Serializers.integer,
  distributorChildrenCount: Serializers.integer,
  chainChildrenCount: Serializers.integer,
});

export type CustomerBulkQueryEntry = SerializerValue<typeof customerBulkQueryEntrySerializer>;

export const tagsSerializer = Serializers.object({
  tags: Serializers.array(Serializers.string),
});

const customerAllowListsSerializer = Serializers.object({
  edit: authAttributeSerializer.array(),
  view: authAttributeSerializer.array(),
  viewHistory: authAttributeSerializer.array(),
});
export type CustomerAllowLists = SerializerValue<typeof customerAllowListsSerializer>;

export const customers = {
  customerBulkQuery: HttpAction({
    path: "/customers/bulk",
    method: "get",
    qsSerializer: Serializers.object({
      customerUuids: uuidSerializer.array().asJsonString(),
      regions: Serializers.string.array().asJsonString(),
    }).partial(),
    valueSerializer: customerBulkQueryEntrySerializer.array(),
    requiresAuth: true as const,
  }),

  bulkCustomerAttributesQuery: HttpAction({
    path: "/customers/bulk/attributes",
    method: "get",
    qsSerializer: Serializers.object({
      customerUuids: uuidSerializer.array().asJsonString(),
    }).partial(),
    valueSerializer: Serializers.object({
      uuid: uuidSerializer,
      authorizedAttributes: customerAllowListsSerializer,
    }).array(),
    requiresAuth: true as const,
  }),

  getCustomer: HttpAction({
    path: "/customer/:customerId",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: customersDetailsSerializer,
    paramsSerializer: Serializers.object({
      customerId: Serializers.string,
    }),
    errorSerializer: Serializers.stringOneOf("CustomerNotFound", "Unauthorized"),
  }),

  saveCustomer: HttpAction({
    path: "/customer/:uuid",
    method: "patch",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }),
    bodySerializer: saveCustomerDtoSerializer,
    valueSerializer: Serializers.object({ uuid: uuidSerializer }),
    errorSerializer: Serializers.stringOneOf("Unauthorized", "NameNotUnique"),
  }),

  syncLoyaltyCustomers: HttpAction({
    path: "/customers/syncLoyaltyCustomers",
    method: "post",
    requiresAuth: true as const,
  }),

  refreshLoyaltyHistory: HttpAction({
    path: "/customers/RefreshLoyaltyHistory",
    method: "post",
    requiresAuth: true as const,
  }),

  syncInvitedUsersRelations: HttpAction({
    path: "/customers/syncInvitedUsersRelations",
    method: "post",
    requiresAuth: true as const,
  }),

  checkCustomerAllowLists: HttpAction({
    path: "/customer/:customerId/allowLists",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      customerId: uuidSerializer,
    }),
    errorSerializer: Serializers.stringOneOf("CustomerNotFound"),
    valueSerializer: customerAllowListsSerializer,
  }),
};
