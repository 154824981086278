import { useTheme } from "@emotion/react";
import { MdQuestionMark, MdShoppingCart, MdStar } from "react-icons/md";

import { CustomerAddress } from "@megaron/crm-contracts";
import { Chip } from "@megaron/dash-form";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { CustomerDoc, CustomerSortField } from "@megaron/docs-contracts";

import { ChildrenCountButton } from "./ChildrenCountButton";
import { CustomerProductsRow, headerText, isInactive, LastActivityText, LastInteractionRow } from "./CustomerTile";
import { OwnerProfile } from "./OwnerProfile";
import { PricingListCell } from "./PricingListCell";
import { Filters } from "./useCustomersFilters";

type Props = {
  customers: CustomerDoc[];
  isLoading: boolean;
  activeSortValue: { field: CustomerSortField; order?: "ASC" | "DESC" | undefined } | undefined;
  onSortChange: (value: { field: CustomerSortField; order?: "ASC" | "DESC" | undefined } | undefined) => void;
  visibleTableColumns: string[];
  queryKey: string | string[];
  onFiltersChange: (newFilters: Filters) => void;
  filters: Filters;
};

export const CustomerTable: React.FC<Props> = ({
  customers,
  isLoading,
  activeSortValue,
  onSortChange,
  visibleTableColumns,
  queryKey,
  onFiltersChange,
  filters,
}) => {
  const theme = useTheme();

  const getActivityColor = (time: Date | null, isArchived: boolean) => {
    if (isArchived) return theme.colors.border;
    if (!time) return theme.colors.primary;
    return isInactive(time) ? theme.colors.danger : theme.colors.success;
  };

  const tableColumns: TableHeadColumn<CustomerSortField>[] = [
    {},
    ...tableHeadingsList.map(({ heading, sortField }) =>
      sortField
        ? ({
            isSortable: true,
            sortFieldName: sortField,
            label: heading,
            isHidden: !visibleTableColumns.includes(heading),
          } as const)
        : ({
            isSortable: false,
            label: heading,
            isHidden: !visibleTableColumns.includes(heading),
          } as const),
    ),
  ];

  const visibleTableColumnsIndexes = tableColumns
    .map((column, index) => (!column.label === undefined || !column.isHidden ? index : null))
    .filter((index) => index !== null) as number[];

  const getTableRowCellsContent = (customer: CustomerDoc): RowCellContent[] => {
    const color = getActivityColor(customer.lastPurchaseTime, customer.isArchived);

    return [
      {
        isLink: true,
        element: (
          <div
            css={{
              position: "absolute",
              inset: 0,
              background: color,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {customer.status === "vip" && <MdStar size={14} color="white" />}
            {customer.status === "unverified" && <MdQuestionMark size={14} color="white" />}
          </div>
        ),
      },
      {
        isLink: true,
        element: (
          <div css={{ display: "flex", alignItems: "center", gap: "0.375rem" }}>
            <span css={{ color, fontWeight: 700, textWrap: "nowrap", fontSize: "0.9375rem" }}>
              {headerText(customer)}
            </span>
            {customer.isPro ? (
              <Chip
                variant="outline"
                color={color}
                css={{ textTransform: "uppercase", padding: "0 8px", fontWeight: "600", fontSize: "10px" }}
              >
                Pro
              </Chip>
            ) : customer.isAffiliate ? (
              <Chip
                variant="outline"
                color={color}
                css={{ textTransform: "uppercase", padding: "0 8px", fontWeight: "600", fontSize: "10px" }}
              >
                Partner
              </Chip>
            ) : null}
            {customer.categories.includes("payer") && (
              <Chip
                variant="outline"
                color={color}
                css={{ textTransform: "uppercase", padding: "0 8px", fontWeight: "600", fontSize: "10px" }}
              >
                Płatnik
              </Chip>
            )}
          </div>
        ),
      },
      {
        isLink: true,
        cellCss: { minWidth: "14rem" },
        element: (
          <div
            css={{
              display: "flex",
              flexWrap: "wrap",
              gap: "0.4rem",
              alignItems: "flex-start",
              fontSize: "0.875rem",
              opacity: "0.6",
              lineHeight: "0.625rem",
            }}
          >
            {customer.tags.map((tag) => (
              <span key={tag}>#{tag}</span>
            ))}
          </div>
        ),
      },
      {
        isLink: true,
        cellCss: { maxWidth: "14rem", overflow: "hidden" },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {parseCustomerAddressToString(customer.address)}
          </span>
        ),
      },
      {
        isLink: true,
        element: (
          <div css={{ width: "20rem" }}>
            <LastInteractionRow customer={customer} />
          </div>
        ),
      },
      {
        isLink: true,
        element: <CustomerProductsRow customer={customer} color={color} hideLastActivityText />,
      },

      {
        isLink: true,
        element: customer.lastPurchaseTime ? (
          <LastActivityText icon={MdShoppingCart} time={customer.lastPurchaseTime} css={{ fontSize: "0.9375rem" }} />
        ) : null,
      },
      {
        cellCss: { padding: "0 0.75rem" },
        element: (
          <ChildrenCountButton
            onClick={() => {
              onFiltersChange({
                ...filters,
                sort: undefined,
                groupFilter: "pos",
                categories: ["pos"],
                relation: { relationType: "chain", parents: [customer.uuid] },
              });
            }}
            text={customer.chainChildrenCount.toString()}
          />
        ),
      },
      {
        cellCss: { padding: "0 0.75rem" },
        element: (
          <ChildrenCountButton
            onClick={() => {
              onFiltersChange({
                ...filters,
                sort: undefined,
                groupFilter: "all",
                categories: undefined,
                relation: { relationType: "distributor", parents: [customer.uuid] },
              });
            }}
            text={customer.distributorChildrenCount.toString()}
          />
        ),
      },
      {
        cellCss: { padding: "0 0.75rem" },
        element: (
          <ChildrenCountButton
            onClick={() => {
              onFiltersChange({
                ...filters,
                sort: undefined,
                groupFilter: "pos",
                categories: ["pos"],
                relation: { relationType: "payer", parents: [customer.uuid] },
              });
            }}
            text={customer.payerChildrenCount.toString()}
          />
        ),
      },
      {
        cellCss: { padding: "0 0.75rem" },
        element: (
          <ChildrenCountButton
            onClick={() => {
              onFiltersChange({
                ...filters,
                sort: undefined,
                groupFilter: "loyalty",
                categories: ["loyaltyUser"],
                relation: { relationType: "supplyPoint", parents: [customer.uuid] },
              });
            }}
            text={customer.supplyPointChildrenCount.toString()}
          />
        ),
      },
      {
        cellCss: { padding: "0 0.75rem" },
        element: (
          <ChildrenCountButton
            onClick={() => {
              onFiltersChange({
                ...filters,
                sort: undefined,
                groupFilter: "loyalty",
                categories: ["loyaltyUser"],
                relation: { relationType: "invitedBy", parents: [customer.uuid] },
              });
            }}
            text={customer.invitedChildrenCount.toString()}
          />
        ),
      },
      {
        element: <OwnerProfile customer={customer} queryKey={queryKey} />,
      },
      {
        isLink: true,
        element: <PricingListCell pricingRules={customer.pricingRules} />,
      },
      {
        isLink: true,
        element: customer.pricingRules?.baseDiscount
          ? `${customer.pricingRules.baseDiscount.mul(100).toFixed(0)}%`
          : "-",
      },
      {
        isLink: true,
        element: (
          <span css={{ textWrap: "nowrap", fontSize: "0.875rem", color: theme.colors.secondaryText }}>
            {customer.pricingRules?.otherTerms ?? "-"}
          </span>
        ),
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
      <Table<CustomerSortField> columns={tableColumns} activeSortValue={activeSortValue} onSort={onSortChange}>
        <TableBody
          visibleColumnIndexes={visibleTableColumnsIndexes}
          isLoading={isLoading}
          rows={customers.map((customer) => ({
            uuid: customer.uuid,
            linkTo: `/crm/customers/id/${customer.uuid}`,
            cellsContent: getTableRowCellsContent(customer),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

export const tableHeadingsList: {
  heading: string;
  sortField?: CustomerSortField;
  isDefaultForLoyalty?: boolean;
  isDefaultForPos?: boolean;
  isDefaultForPayer?: boolean;
  isDefaultForChain?: boolean;
  isDefaultForDistributor?: boolean;
}[] = [
  {
    heading: "Nazwa",
    isDefaultForLoyalty: true,
    isDefaultForPos: true,
    isDefaultForChain: true,
    isDefaultForDistributor: true,
    isDefaultForPayer: true,
  },
  {
    heading: "Tagi",
    isDefaultForLoyalty: true,
    isDefaultForPos: true,
    isDefaultForChain: true,
    isDefaultForDistributor: true,
    isDefaultForPayer: true,
  },
  {
    heading: "Adres",
    isDefaultForPos: true,
    isDefaultForChain: true,
    isDefaultForDistributor: true,
    isDefaultForPayer: true,
  },
  {
    heading: "Ostatnia interakcja",
    sortField: "lastInteractionTime",
    isDefaultForLoyalty: true,
    isDefaultForPos: true,
    isDefaultForChain: true,
    isDefaultForDistributor: true,
    isDefaultForPayer: true,
  },
  { heading: "Całkowite zakupy", sortField: "totalMassKg", isDefaultForLoyalty: true },
  {
    heading: "Ostatni zakup",
    sortField: "lastPurchaseTime",
    isDefaultForLoyalty: true,
    isDefaultForPos: true,
    isDefaultForChain: true,
    isDefaultForDistributor: true,
    isDefaultForPayer: true,
  },
  { heading: "Powiązani z siecią", sortField: "chainChildrenCount", isDefaultForChain: true },
  {
    heading: "Powiązani z dystrybutorem",
    sortField: "distributorChildrenCount",
    isDefaultForDistributor: true,
  },
  { heading: "Powiązani z płatnikiem", sortField: "payerChildrenCount", isDefaultForPayer: true },
  { heading: "Liczba wykonawców", sortField: "supplyPointChildrenCount", isDefaultForPos: true },
  { heading: "Liczba zaproszonych", sortField: "invitedChildrenCount", isDefaultForLoyalty: true },
  {
    heading: "Region",
    isDefaultForLoyalty: true,
    isDefaultForPos: true,
    isDefaultForChain: true,
    isDefaultForDistributor: true,
    isDefaultForPayer: true,
  },
  {
    heading: "Cennik",
    isDefaultForPayer: true,
  },
  {
    heading: "Rabat podstawowy",
    isDefaultForPayer: true,
  },
  {
    heading: "Pozostałe warunki",
    isDefaultForPayer: true,
  },
];

export const parseCustomerAddressToString = (address: CustomerAddress) => {
  if (!address || Object.values(address).every((value) => !value)) return "-";

  return `${address.street}${address.postalCode ? (address.street ? ", " + address.postalCode : address.city) : ""}${
    address.city
      ? address.street || address.postalCode
        ? address.postalCode
          ? " " + address.city
          : ", " + address.city
        : address.postalCode
      : ""
  }${address.country ? ", " + address.country : ""}`;
};
