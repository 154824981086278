import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const contactSerializer = Serializers.object({
  uuid: uuidSerializer,
  customerId: uuidSerializer,
  name: Serializers.string,
  email: Serializers.string,
  phoneNumber: Serializers.string,
  jobTitle: Serializers.string,
});

export type Contact = SerializerValue<typeof contactSerializer>;

export const contactEntrySerializer = Serializers.object({
  customerName: Serializers.string,
  name: Serializers.string,
  email: Serializers.string,
  phoneNumber: Serializers.string,
  jobTitle: Serializers.string,
  region: Serializers.string,
  macroregion: Serializers.string,
});

export type ContactEntry = SerializerValue<typeof contactEntrySerializer>;

export const allContactsQuerySerializer = contactEntrySerializer.array();

export const contacts = {
  customerContactsQuery: HttpAction({
    path: "/customers/:customerId/contacts",
    method: "get",
    paramsSerializer: Serializers.object({
      customerId: Serializers.string,
    }),
    requiresAuth: true as const,
    valueSerializer: contactSerializer.array(),
  }),

  saveCustomerContact: HttpAction({
    path: "/customers/contacts",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: contactSerializer,
    valueSerializer: contactSerializer,
  }),

  removeCustomerContact: HttpAction({
    path: "/customers/contacts/:contactUuid",
    method: "delete",
    paramsSerializer: Serializers.object({
      contactUuid: uuidSerializer,
    }),
    requiresAuth: true as const,
  }),

  allContactsQuery: HttpAction({
    path: "/customers/contacts/all",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: allContactsQuerySerializer,
  }),
};
